// Override default variables before the import
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

// Colours

$fuschia: #c7017f;
$ivory: #f3f2ef;
$ink: #1a1e52;
$inklight: #8788a1;
$cerulian: #61b2c1;
$white: #ffffff;

// Fonts

@font-face {
  font-family: "Centra-Light";
  src: url("./fonts/CentraNo1-Light.woff") format("woff");
  src: url("./fonts/CentraNo1-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Centra-Book";
  src: url("./fonts/CentraNo1-Book.woff") format("woff");
  src: url("./fonts/CentraNo1-Book.woff2") format("woff2");
}

@font-face {
  font-family: "Centra-Bold";
  src: url("./fonts/CentraNo1-Bold.woff") format("woff");
  src: url("./fonts/CentraNo1-Bold.woff2") format("woff2");
}

html {
  font-size: 25px;
  font-size: 0.98vw;
  @media (max-width: 2000px) {
    font-size: 30px;
    font-size: 1.152vw;
  }
  @include media-breakpoint-down(lg) {
    font-size: 32px;
    font-size: 1.2544vw;
  }
  @include media-breakpoint-down(md) {
    font-size: 38px;
    font-size: 1.5vw;
  }
  @include media-breakpoint-down(sm) {
    font-size: 25px;
  }
  @include media-breakpoint-down(xs) {
  }
}
body {
  font-family: "Centra-Light", Arial, sans-serif;
  cursor: url("./images/cursor.png"), auto;
  overflow-x: hidden;
  background: $ink;

  // Override grid gutters for Kotahi
  .row > .col,
  .row > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  img {
    width: 100%;
    height: auto;
  }
  h2 {
    font-size: 80px;
    font-size: 3.2rem;
    line-height: 110%;
    color: $cerulian;
    strong {
      font-family: "Centra-Bold", Arial, sans-serif;
    }
  }
  a:hover {
    color: $fuschia !important;
    text-decoration: none;
  }
  .outer-wrapper {
    background: $ivory;
    max-width: 2560px;
    margin: 0 auto;
  }
}

/********************************
*
*  Header Module
*
********************************/

.header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 120px; //not in rem to maintain height and fit nav - orig was 132px
  background: linear-gradient(180deg, $ink 0%, rgba(0, 0, 0, 0) 100%);

  .menu-bars {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.5s;
    @include media-breakpoint-down(lg) {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }

  @include media-breakpoint-down(lg) {
    height: 94px;
    background: $ink;
  }
  .wrapper {
    display: block;
    max-width: 2560px;
    margin: 0 auto;
    padding: 30px 40px;
    .logo {
      width: auto;
      height: 41px;
      position: relative;
      z-index: 100;
    }
    .header-right {
      @include media-breakpoint-between(sm, lg) {
        margin-left: 5%;
      }
    }
    ul {
      padding-top: 10px;
      li {
        display: inline-block;
        margin-right: 4%;
        @media (min-width: 1600px) {
          margin-right: 6%;
        }
        @media (min-width: 1800px) {
          margin-right: 8%;
        }
        @media (min-width: 2000px) {
          margin-right: 10%;
        }
        &:last-child {
          margin-right: 0;
        }
        margin-bottom: 14px !important;
        line-height: 18px !important;
        a {
          color: $white;
          font-size: 14px;
          letter-spacing: 0.7px;
          text-transform: uppercase;
        }
      }
      @include media-breakpoint-down(lg) {
        display: block;
        width: 50%;
        background: $ink;
        position: fixed;
        top: 0;
        height: 100vh;
        right: 0;
        padding: 4.8rem 0 0 0.8rem;
        padding: 120px 0 0 20px;
        opacity: 0;
        right: -50%;
        transition: all 0.5s;
        li {
          display: block;
          margin-bottom: 1rem;
        }
        &.open {
          right: 0;
          opacity: 1;
          transition: all 0.5s;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }
  }
  transition: all 0.5s;
  &.sticky {
    background: $ink;
    transition: all 0.5s;
  }
  /********************************
  *
  *   Breakpoint highlight
  *   REMOVE BEFORE PRODUCTION DEPLOY!
  *
  ********************************/
  // @media (min-width: 2000px) {
  //   border-top: 10px solid rgb(52, 11, 202);
  // }
  // @include media-breakpoint-down(xl) {
  //   border-top: 10px solid rgb(202, 11, 129);
  // }
  // @include media-breakpoint-down(lg) {
  //   border-top: 10px solid green;
  // }
  // @include media-breakpoint-down(md) {
  //   border-top: 10px solid yellow;
  // }
  // @include media-breakpoint-down(sm) {
  //   border-top: 10px solid orange;
  // }
  // @include media-breakpoint-down(xs) {
  //   border-top: 10px solid red;
  // }
}

/********************************
*
*  Hero Banner Module
*
********************************/

.hero-banner {
  position: relative;
  max-width: 2560px;
  .intro-animation {
    position: absolute;
    margin: 0 auto;
    z-index: 1000;
    height: 100vh;
    width: 100%;
    top: 0;
    background: $ink;
    opacity: 1;
    transition: opacity 0.5s;
    .content-area {
      max-width: 2560px;
      height: auto;
    }
  }

 

  .wrapper {
    display: block;
    max-width: 2560px;
    margin: 0 auto 0 auto;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0px; //to fit project panels below on mobile
    }
    padding: 0;
    background: $ink;
    position: relative;
    
    .horizontal {
      
      color: $cerulian;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 13px;
      svg {
        margin-left: 0px;
      }
      display: none;
      padding-bottom: 20px;
      @include media-breakpoint-down(lg) {
       display: block;
      }
      @media (max-width: 320px) {
        width: 65%;
      }
    }

    .boundaries {
      position: relative;

      .vertical {
        position: absolute;
        color: $cerulian;
        text-transform: uppercase;
        letter-spacing: 4px;
        font-size: 13px;
        z-index: 100;
        bottom: 10%;
        left: 50%;
        transform: rotate(270deg);
        transform-origin: 0 0;
        svg {
          margin-left: 40px;
        }
        @include media-breakpoint-down(lg) {
          display: none;
        }
        
      }
      
      .semi-circle {
        display: block;
        content: " ";
        position: absolute;
        top: 5.28rem; //132px header height
        left: 0;
        z-index: 5;
        background: url(./images/overlay-semi-circle.svg) top left no-repeat;
        background-size: 100%;
        width: 27.2rem; //680px
        height: 17.24rem; //431px;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      .line-left {
        display: block;
        content: " ";
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -9.44rem; // 204.5px healf of width
        z-index: 5;
        background: url(./images/overlay-parallelogram.svg) top left no-repeat;
        background-size: 100%;
        width: 18.88rem; //472px
        height: 16.36rem; //409px;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      .triangle {
        display: block;
        content: " ";
        position: absolute;
        top: 5.8rem; //277px - 132 margin - header height = 145px
        right: 0;
        z-index: 5;
        background: url(./images/overlay-triangle.svg) no-repeat;
        background-size: 100%;
        width: 9.8rem; //245px
        height: 9.8rem; //245px;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      .shape {
        opacity: 0;
      }
      .large-image {
        position: relative;
        left: -100%;
      }
    }
    .content {
      
    

      
      padding: 50vh 0 0 0;
      padding: 8rem 0 0 0;
      opacity: 0;
      .animated-heading {
        margin-left: -2rem;
      }
      @media (max-width: 576px) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
      @include media-breakpoint-down(md) {
        .animated-heading {
          margin-left: -1.5rem;
          width: 70%;
        }
      }

      @include media-breakpoint-down(sm) {
        .animated-heading {
          margin-left: -0.5rem;
          width: 60%;
        }
        padding-top: 44px;
        position: relative;
        &:before {
          display: block;
          content: " ";
          position: absolute;
          z-index: 1;
          top: -7.06rem; //half height of svg 7.08rem - half top padding (22px = 0.88rem) = 7.12rem
          left: 0rem;
          margin-left: 15px;
          z-index: 5;
          background: url(./images/overlay-parallelogram.svg) top left no-repeat;
          background-size: 100%;
          width: 8.16rem; //204px
          height: 7.08rem; // 177px
          // clip-path: inset(0rem 4.15rem 8.86rem 0rem);
          @include media-breakpoint-between(sm, md) {
            margin-left: -10px;
            top: -10.4rem;
            width: 12rem; //300px
            height: 10.4rem; //260px;
          }
        }
      }

      width: 100%;
      max-width: 580px;
      max-width: 23.2rem;
      @include media-breakpoint-between(sm, md) {
        margin-left: 25px;
        margin-right: 25px;
        max-width: 100%;
      }
      @include media-breakpoint-between(md, lg) {
        margin-left: 5%;
      }
      h2 {
        margin-bottom: 60px;
        margin-bottom: 2.4rem;
        @include media-breakpoint-down(sm) {
          margin-bottom: 23px;
          font-size: 40px;
          line-height: 42px;
          position: relative;
          &:after {
            display: block;
            content: " ";
            position: absolute;
            bottom: 0; //277px - 132 margin - header height = 145px
            right: -10px;
            z-index: 5;
            background: url(./images/overlay-triangle.svg) no-repeat;
            background-size: 100%;
            width: 5.04rem; //126px
            height: 5.04rem; //126px;
          }
        }
      }
      p {
        color: $white;
        line-height: 32px;
        line-height: 1.28rem;
        font-size: 27px;
        font-size: 1.08rem;
        margin-bottom: 1.28rem;
        @include media-breakpoint-down(md) {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 18px;
        }
        a {
          font-size: 14px;
          font-size: 0.56rem;
          text-transform: uppercase;
          color: $white;
          margin: 60px 0 0 -8px;
          margin: 2.4rem 0 0 -0.32rem;
          margin: 0rem 0 0 -0.32rem;
          padding: 0 0 0 29px;
          padding: 0 0 0 1.16rem;
          background: url(./images/chevron.svg) left 9px no-repeat;
          background: url(./images/chevron.svg) 0.12rem 0.4rem no-repeat; //10px
          background-size: 0.48rem; //12px
          display: block;
          @media (max-width: 2000px) {
            background: url(./images/chevron.svg) -6px 6px no-repeat;
            background-size: 10px;
            padding-left: 20px;
            font-size: 12px;
            letter-spacing: 0.5px;
            margin: 5px 0 59px;
          }
          @media (max-width: 1400px) {
            background-position: -6px 3px;
          }
          @include media-breakpoint-down(md) {
            margin: 0px 0 0 -8px;
            margin: 0 0 0 -0.32rem;
            background: url(./images/chevron.svg) -6px 3px no-repeat;
            background-size: 12px;
            padding-left: 25px;
            font-size: 14px;
            letter-spacing: 0.7px;
            margin: 25px 0 59px;
          }
        }
      }
    }
  }
  &.animating {
    .intro-animation {
      opacity: 0;
      z-index: -1;
      transition: opacity 2s, z-index 2s;
    }
    .wrapper {
      .boundaries {
        .large-image {
          left: 0;
          transition: left 1.5s 1s;
        }
        .shape {
          opacity: 1;
          transition: opacity 5s 1s;
        }
      }
      .content {
        opacity: 1;
        transition: opacity 5s 1s;
      }
    }
  }
}

/********************************
*
*  Large Hero Image Module
*
********************************/

.large-hero-image {
  @include media-breakpoint-down(lg) {
    img {
      margin-top: 90px;
      min-height: 25rem;
      object-fit: cover;
      object-position: center center;
    }
  }
  @include media-breakpoint-down(md) {
    img {
      min-height: 20rem;
    }
  }
  @include media-breakpoint-down(msmd) {
    img {
      min-height: 14rem;
    }
  }
}

/********************************
*
*  Slim Intro Banner Module
*
********************************/

.slim-intro-panel {
  scroll-margin-top: 100px;
  background-color: $ink;
  color: $white;
  padding-bottom: 30px;
  padding-top: 132px; //space for header and nav
  &.padding-below {
    padding-bottom: 30px;  
  }
  &.null {
    padding-top: 0;
  }
  &.ivory {
    background-color: $ivory;
    color: $ink;
    h2 {
      color: $ink;
    }
  }
  @include media-breakpoint-down(sm) {
    position: relative;
    padding-top: 94px;
    padding-left: 20px;
    padding-right: 20px;
  }
  h2 {
    font-size: 2rem; //50px
    line-height: 2rem; //50px
    color: $white;
    @include media-breakpoint-down(sm) {
      margin-top: 1.64rem; //41px
      margin-bottom: 0.92rem; //23px (37px 1.48rem)
      font-size: 1.4rem; //40px
      line-height: 1.68rem; //42px
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .slim-intro-panel-text {
    // margin-top: 3.96rem; //231px - 132px = 99px
    // margin-bottom: 3.96rem; // to match margin-top; only visible on mid-sizes
    padding-left: 8.4rem !important; //210px
    padding-right: 10.6rem !important; //265px
    @include media-breakpoint-down(sm) {
      margin-top: 0;
      padding-left: 15px !important;
      padding-right: 15px !important;
      margin-bottom: 0.88rem; // 40-18px p margin above = 22px
    }
    position: relative;
    @include media-breakpoint-down(sm) {
      position: unset;
    }
    p {
      font-size: 1.08rem; //27px
      line-height: 1.4rem; //35px
      @include media-breakpoint-down(sm) {
        font-size: 0.56rem; //14px
        line-height: 0.72rem; //18px
        letter-spacing: 0.0112rem; //0.28px
        margin-bottom: 0.72rem; //18px
      }
    }
  }
  &.null {
    h2,
    .slim-intro-panel-text {
      margin-top: 3.2rem;
      margin-bottom: 3.6rem;
      @include media-breakpoint-down(sm) {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
      }
    }
    @include media-breakpoint-down(sm) {
      .slim-intro-panel-text {
        margin-top: 0;
      }
    }
  }
  &.white {
    background: $white;
    h2 {
      color: $ink;
    }
    color: $ink;
    a {
      color: $cerulian;
    }
  }
  // *** intro panel shapes
  &.triangle {
    .slim-intro-panel-text {
      &:before {
        content: " ";
        display: block;
        position: absolute;
        top: -3.96rem; // margin-top - header height 132px
        left: 4.2rem; // padding-left 8.4rem /2
        z-index: 5;
        background: url(./images/overlay-triangle.svg) no-repeat;
        background-size: 100%;
        width: 9.8rem; //245px
        height: 9.8rem; //245px;
        @include media-breakpoint-down(sm) {
          top: 135px; //41px + 52px title height
          right: 0;
          left: auto;
          width: 5.04rem; // 126px
          height: 5.04rem; // 126px
        }
      }
    }
  }
  &.semi-circle {
    .slim-intro-panel-text {
      &:before {
        content: " ";
        display: block;
        position: absolute;
        top: -3.96rem; // margin-top - header height 132px
        left: -7.5rem;
        z-index: 5;
        background: url(./images/overlay-semi-circle.svg) no-repeat;
        background-size: 100%;
        width: 27.2rem; //680px
        height: 17.2rem; //431px;
        transform: rotate(-120deg);
        @include media-breakpoint-down(sm) {
          top: 135px; //41px + 52px title height
          right: -5px;
          left: auto;
          width: 7rem; // 175px
          height: 4.44rem; // 111px
        }
      }
    }
  }
  &.line-right {
    .slim-intro-panel-text {
      &:before {
        content: " ";
        display: block;
        position: absolute;
        top: -4.65rem; // margin-top - header height 132px
        left: -5.5rem;
        z-index: 5;
        background: url(./images/overlay-parallelogram.svg) no-repeat;
        background-size: 100%;
        width: 20.2rem; //680px
        height: 20.2rem; //431px;
        transform: scaleX(-1);
        @include media-breakpoint-between(md, lg) {
          top: -4.75rem;
          left: -9.5rem;
          width: 23.3rem;
          height: 23.3rem;
        }
        @include media-breakpoint-between(sm, md) {
          top: -4.75rem;
          left: -9.5rem;
          width: 24.8rem;
          height: 24.8rem;
        }
        @include media-breakpoint-down(sm) {
          top: 135px; //41px + 52px title height
          right: -5px;
          left: auto;
          width: 7rem; // 175px
          height: 4.44rem; // 111px
        }
      }
    }
  }
  &.line-left {
    .slim-intro-panel-text {
      &:before {
        content: " ";
        display: block;
        position: absolute;
        top: -4.65rem; // margin-top - header height 132px
        left: -5.5rem;
        z-index: 5;
        background: url(./images/overlay-parallelogram-left.svg) no-repeat;
        background-size: 100%;
        width: 20.2rem; //680px
        height: 20.2rem; //431px;
        @include media-breakpoint-between(md, lg) {
          top: -4.75rem;
          left: -9.5rem;
          width: 23.3rem;
          height: 23.3rem;
        }
        @include media-breakpoint-between(sm, md) {
          top: -4.75rem;
          left: -9.5rem;
          width: 24.8rem;
          height: 24.8rem;
        }
        @include media-breakpoint-down(sm) {
          top: 135px; //41px + 52px title height
          right: -5px;
          left: auto;
          width: 7rem; // 175px
          height: 4.44rem; // 111px
        }
      }
    }
  }
}

/********************************
*
*  Projects Module
*
********************************/

.project-results {
  padding: 2.4rem;
  @include media-breakpoint-down(sm) {
    padding: .7rem;
  }
}

.projects {
  padding: 2rem 0;
  .filter-container {
    padding-bottom: 2rem;

    .project-filter {
      position: absolute;
      z-index: 100;
      background: url(./images/small-parallelogram.svg) left top no-repeat;
      background-size: 1.4rem 1.4rem;
      padding-left: 1.9rem;
      height: 3rem;
      @include media-breakpoint-down(sm) {
        margin-left: 10px;
      }
      .filter-text {
        color: $inklight;
        text-transform: uppercase;
        @include media-breakpoint-down(sm) {
          font-size: 16px;
          line-height: 200%;
        }
        .current {
          text-transform: none;
          color: $ink;
          font-weight: 500;
          font-family: "Centra-Bold", sans-serif;
          background: url(./images/chevron-down.svg) right center no-repeat;
          padding-right: 30px;
        }
        
      }
      .filter-list {
        opacity: 0;
        display: none;
        background: $ivory;
        padding: 1.4rem;
        position: relative;
        left: 6rem;
        transition: opacity 0.2s 0.5s;
        .dropdown {
          li {
            text-transform: none;
            color: $ink;
          }
        }
        &.open {
          opacity: 1;
          display: block;
          transition: opacity 0.2s 0.5s;
        }
        @include media-breakpoint-down(sm) {
          font-size: 20px;
          line-height: 200%;
          left: 0%;
        }
      }
    }
    transition: all 0.5s;
    &.sticky-filter {
      position: fixed;
      top: 120px;
      width: 100%;
      z-index: 100;
      background: $white;
      max-width: 2560px;
      margin: 0 auto;
      padding: 0.75rem 0;
      height: 3rem;
      transition: all 0.5s;
      @include media-breakpoint-down(lg) {
        top: 90px;
      }
    }
  }
  h3.contact-link {
    padding: 1.4rem 0 2.4rem 0;
    margin-left: 49.7%;
    padding-top: 4rem;
    @media (max-width: 2000px) {
      margin-left: 49.7%;
    }
    @include media-breakpoint-down(sm) {
      margin-left: 20px;
      line-height: 0.76rem;
      padding-top: 0rem;
    }
    @include media-breakpoint-down(xs) {
      margin-left: 20px;
    }
    margin-bottom: 0;
    a {
      color: $cerulian;
      text-transform: uppercase;
      font-family: "Centra-Light";
      font-size: 1.6rem; //40px
      line-height: 2.08rem; // 52px
      letter-spacing: 0;
      padding-left: 3rem;
      background: url(./images/chevron.svg) 0.1rem 0.24rem no-repeat;
      background-size: 1.6rem;
      @include media-breakpoint-down(sm) {
        font-size: 0.76rem; //19px
        line-height: 1rem;
        padding-left: 1.12rem; //28px
        background-size: 1.12rem;
        background-position-x: -0.56rem;
        background-position-y: -0.02rem;
        padding-top: 2px;
        padding-bottom: 2px;
      }
      @media (max-width: 389px) {
        font-size: 0.6rem;
        background-size: 1rem;
      }
    }
  }
}

/********************************
*
*  Project Panel Module
*
********************************/

.project-panel {

  // padding-top: 1rem;
  // padding-bottom: 2em;
  &.last-panel {
    padding-bottom: 2rem;
  }
  .wrapper {
    background: $ivory;
    display: block;
    max-width: 2560px;
    margin: 0 auto;
    padding: 1.6rem 2.4rem 0 2.4rem;

    &.full {
      max-width: none;
      width: 100%;
      padding: 0;
    }
    
    .row {
      position: relative;
      h3.contact-link {
        position: absolute;
        bottom: 0;
        padding: 1.4rem 0 0 0;
        margin-left: -10px;
        @include media-breakpoint-down(sm) {
          margin-left: 20px;
          line-height: 0.76rem;
          padding-top: 0rem;
        }
        @include media-breakpoint-down(xs) {
          margin-left: 20px;
        }
        margin-bottom: 0;
        a {
          color: $cerulian;
          text-transform: uppercase;
          font-family: "Centra-Light";
          font-size: 1.3rem; //40px
          line-height: 2.08rem; // 52px
          letter-spacing: 0;
          padding-left: 2rem !important;
          background: url(./images/chevron.svg) 0.1rem 0.24rem no-repeat;
          background-size: 1.6rem;
          @include media-breakpoint-down(sm) {
            font-size: 0.76rem; //19px
            line-height: 1rem;
            padding-left: 1.12rem; //28px
            background-size: 1.12rem;
            background-position-x: -0.56rem;
            background-position-y: -0.02rem;
            padding-top: 2px;
            padding-bottom: 2px;
            padding-left: 1rem !important;
          }
          @media (max-width: 389px) {
            font-size: 0.6rem;
            background-size: 1rem;
          }
        }
      }

      @include media-breakpoint-down(md) {
        padding-bottom: 2rem;
        margin-bottom: 0rem;
      }

      @include media-breakpoint-down(sm) {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }
    }


    .caption {
      display: block;
      margin-top: 10px;
      margin-top: 0.28rem; //7px
      background: url("./images/chevron-up.svg") left 9px no-repeat;
      background: url("./images/chevron-up.svg") left 0.6rem no-repeat; //15px
      background-size: 14px;
      background-size: 0.56rem;
      padding-left: 30px;
      padding-left: 1.2rem;
      a {
        font-family: "Centra-Book";
        font-size: 14px;
        font-size: 0.56rem;
        letter-spacing: 0.9px;
        letter-spacing: 0.036rem;
        color: $ink;
        line-height: 0;
        @media (max-width: 2000px) {
          font-size: 11px;
          letter-spacing: 0.71px;
        }
      }
      &.bottom {
        position: absolute;
        bottom: -18px;
        bottom: -0.72rem;
        background: url("./images/chevron-left.svg") left 6px no-repeat;
        background: url("./images/chevron-left.svg") left 0.56rem no-repeat; //14px
        background-size: 14px;
        background-size: 0.56rem;
      }
      &.right {
        position: absolute;
        bottom: -18px;
        bottom: -0.72rem;
        right: 15px;
        right: 0.6rem;
        background: url("./images/chevron.svg") right 6px no-repeat;
        background: url("./images/chevron.svg") right 0.56rem no-repeat; //14px
        background-size: 14px;
        background-size: 0.56rem;
        text-align: right;
        padding-right: 30px;
        padding-right: 1.2rem;
      }
    }
    .content-area {
      position: relative;
      .rollover-content {
        // font-size: 26px;
        // font-size: 1.0192vw;
        position: absolute;
        top: 0;
        background: $ink;
        width: 100%;
        height: 100%;
        opacity: 0;
        padding: 10px 20px;
        @include media-breakpoint-down(lg) {
          padding: 15px;
        }
        @media (max-width: 1100px) {
          padding: 7px 11px;
        }
        @include media-breakpoint-down(md) {
          padding: 40px;
        }
        @include media-breakpoint-down(sm) {
          padding: 20px;
        }
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        h3 {
          color: $cerulian;
          font-family: "Centra-Bold";
          font-size: 50px;
          font-size: 2rem;
          letter-spacing: 0px;
          line-height: 48px;
          line-height: 1.92rem;
          margin-bottom: 30px;
          margin-bottom: 1.2rem;
          max-width: 306px;
          max-width: 12.24rem;
          @media (max-width: 1800px) {
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 20px;
          }
          @media (max-width: 1600px) {
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 10px;
            max-width: none;
          }
          @media (max-width: 1400px) {
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 10px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 18px;
            line-height: 18px; //reduced from 20px to keep title in the area
            margin-bottom: 6px;
          }
          @include media-breakpoint-down(md) {
            font-size: 40px;
            line-height: 42px;
            margin-bottom: 30px;
          }
          @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 20px;
          }
        }
        h4 {
          color: $cerulian;
          font-family: "Centra-Bold";
          font-size: 14px;
          font-size: 0.56rem;
          letter-spacing: 0.28px;
          letter-spacing: 0.0112rem;
          line-height: 18px;
          line-height: 0.72rem;
          @media (max-width: 1800px) {
            font-size: 14px;
            line-height: 18px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 11px;
            line-height: 12px;
          }
          @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        ul {
          margin: 0;
          color: $white;
          font-family: "Centra-Book";
          font-size: 14px;
          font-size: 0.56rem;
          letter-spacing: 0.28px;
          letter-spacing: 0.0112rem;
          line-height: 18px;
          line-height: 0.72rem;
          @media (max-width: 1800px) {
            font-size: 12px;
            line-height: 14px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 11px;
            line-height: 12px;
          }
          @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 18px;
          }
          li {
            list-style-type: none;
            background: url("./images/chevron.svg") -4px 4px no-repeat;
            background: url("./images/chevron.svg") -0.16rem 0.16rem no-repeat;
            background-size: 8px;
            background-size: 0.32rem;
            padding-left: 18px;
            padding-bottom: 8px;
            padding-left: 0.72rem;
            padding-bottom: 0.32rem;
          }
        }
        transition: all 0.5s;
        &.open {
          opacity: 1;
          transition: all 0.5s;
        }
      }
    }
    @include media-breakpoint-down(md) {
      //.wrapper
      padding: 2rem;
      .caption {
        margin: 0.88rem 0 0.96rem; // 64-18 = 23px = 0.92rem, distributed to account for line height
        margin: 22px 0 24px;
        padding-left: 54px;
        background: url("./images/chevron-up.svg") 23px 6px no-repeat; //15px
        //background-size: 8px;
        p {
          margin-bottom: 0;
          line-height: 18px;
          //line-height: 0.72rem;
          a {
            font-size: 14px;
            letter-spacing: 0.9px;
          }
        }
      }
      // .l-s-s {
      //   .large-project {
      //     margin-bottom: 2.56em; //64px
      //   }
      //   .small-project-1 {
      //     .caption.bottom {
      //       top: -2rem;
      //       margin-top: 10px;
      //       margin-top: 0.28rem; //7px
      //       padding-left: 54px;
      //       background: url("./images/chevron-up.svg") 23px 6px no-repeat; //15px
      //       background-size: 14px;
      //       background-size: 0.56rem;
      //     }
      //   }
      // }
      // .s-s-l {
      //   .large-project {
      //     margin-bottom: 2.56em; //64px
      //   }
      //   .small-project-2 {
      //     position: relative;
      //     .caption.bottom.right {
      //       bottom: -15.9rem;
      //       left: 0;
      //       text-align: left;
      //       margin-top: 10px;
      //       margin-top: 0.28rem; //7px
      //       margin: 0.88rem 0 0.96rem;
      //       padding-left: 64px;
      //       background: url("./images/chevron-up.svg") 33px 6px no-repeat; //15px
      //       background-size: 14px;
      //       background-size: 0.56rem;
      //     }
      //   }
      // }
    }
    @include media-breakpoint-down(sm) {
      padding: 1rem;
      img {
        height: 10rem;
        object-fit: cover;
        object-position: center center;
        margin-bottom: 0.5rem;
      }
      .spacer {
        display: none !important;
      }
    }
   
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
    &:first-of-type .wrapper {
      padding-top: 1rem;
    }
    &:last-of-type .wrapper {
      padding-bottom: 1rem;
    }
  }
}

/********************************
*
*  Project Panel Module
*
********************************/

.related-projects {
  background: $ivory;
  display: block;
  max-width: 2560px;
  margin: 0 auto;
  padding: 2rem 2.4rem 0 2.4rem;

  .caption {
    background: url("./images/chevron-up.svg") 0 0.75rem no-repeat;
    padding-left: 1.3rem;
    a {
      color: $ink;
      font-size: 11px;
      letter-spacing: 0.71px;
    }
  }
  h4 {
    font-size: 1.2rem;
    color: $ink;
    font-family: "Centra-Bold", sans-serif;
  }
  h3.contact-link {
    padding: 1.4rem 0 2.4rem 0;
    margin-left: 49.7%;

    @media (max-width: 2000px) {
      margin-left: 49.7%;
    }
    @include media-breakpoint-down(sm) {
      margin-left: 20px;
      line-height: 0.76rem;
      padding-top: 0rem;
    }
    @include media-breakpoint-down(xs) {
      margin-left: 20px;
    }
    margin-bottom: 0;
    a {
      color: $cerulian;
      text-transform: uppercase;
      font-family: "Centra-Light";
      font-size: 1.6rem; //40px
      line-height: 2.08rem; // 52px
      letter-spacing: 0;
      padding-left: 3rem;
      background: url(./images/chevron.svg) 0.1rem 0.24rem no-repeat;
      background-size: 1.6rem;
      @include media-breakpoint-down(sm) {
        font-size: 0.76rem; //19px
        line-height: 1rem;
        padding-left: 1.12rem; //28px
        background-size: 1.12rem;
        background-position-x: -0.56rem;
        background-position-y: -0.02rem;
        padding-top: 2px;
        padding-bottom: 2px;
      }
      @media (max-width: 389px) {
        font-size: 0.6rem;
        background-size: 1rem;
      }
    }
  }
  img {
    width: 100%;
    height: auto;
  }
}

/********************************
*
*  Project Banner Module
*
********************************/

.project-banner {
  .wrapper {
    max-width: 2560px;
    margin: 0 auto;
    ul {
      margin: 1.5rem 0 4rem 0;
      li {
        display: inline-block;
        color: $white;
        font-size: 0.6rem;
        margin-right: 3rem;
        .prefix {
          display: inline-block;
          margin-left: 0.4rem;
          margin-right: 0.3rem;
          text-transform: uppercase;
          color: $inklight;
        }
        .types {
          color: $white;
          text-transform: none;
        }
        img {
          height: 0.6rem;
          width: auto;
          position: relative;
          top: -0.07rem;
        }
      }
    }
    .content {
      color: $white;
      margin-bottom: 5rem;
      h2 {
        color: $cerulian;
        font-family: "Centra-Bold";
        font-size: 40px;
        letter-spacing: 0px;
        line-height: 50px;
        margin-bottom: 30px;
        max-width: 306px;
        @include media-breakpoint-down(lg) {
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 6px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 30px;
          line-height: 36px;
          margin-bottom: 6px;
        }
      }
      h4 {
        color: $cerulian;
        font-family: "Centra-Bold";
        font-size: 14px;
        font-size: 0.56rem;
        letter-spacing: 0.28px;
        letter-spacing: 0.0112rem;
        line-height: 18px;
        line-height: 0.72rem;
        @include media-breakpoint-down(lg) {
          font-size: 11px;
          line-height: 12px;
        }
      }
      ul {
        margin: 0;
        color: $white;
        font-family: "Centra-Book";
        font-size: 14px;
        font-size: 0.56rem;
        letter-spacing: 0.28px;
        letter-spacing: 0.0112rem;
        line-height: 18px;
        line-height: 0.72rem;
        margin-bottom: 20px;
        @include media-breakpoint-down(lg) {
          font-size: 11px;
          line-height: 12px;
        }
        li {
          list-style-type: none;
          display: block;
          background: url("./images/chevron.svg") -4px 4px no-repeat;
          background: url("./images/chevron.svg") -0.16rem 0.3rem no-repeat;
          background-size: 8px;
          background-size: 0.5rem;
          padding-left: 18px;
          padding-bottom: 8px;
          padding-left: 0.72rem;
          padding-bottom: 0.32rem;
          font-size: .9rem;
          line-height: 1.4rem;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding: 4rem 0 0 0;
      ul {
        margin: 1rem 1rem;
      }
      .content {
        padding: 0 1rem;
        margin-bottom: 1rem;
        h2 {
          margin-top: 1rem;
        }
        h4 {
          margin-top: 1.8rem;
          font-size: 0.8rem;
        }
        ul {
          margin-top: 1rem;
          margin-bottom: 1rem;
          li {
            background-position: 0 8px;
            padding-bottom: 0.5rem;
            line-height: 1rem;
          }
        }
        p {
          font-size: 0.7rem;
        }
      }
    }
  }
}

/********************************
*
*  Project Detail Spacer Module (Until I can work out how to add padding to the last-child (curently won't work on divs)
*
********************************/

.spacer {
  height: 2rem;
  max-width: 2560px;
  margin: 0 auto;
  background: $ivory;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

/********************************
*
*  Breakout Panel Module
*
********************************/

.break-out-panel {
  background: $ink;
  margin: 0px 0 2px;
  margin: 0 0 0.08rem;
  padding-top: 3.6rem;
  @include media-breakpoint-down(md) {
    margin: 0 0 0.08rem;
  }
  .wrapper {
    display: block;
    max-width: 2560px;
    margin: 0 auto;
    padding: 0px 60px;
    padding: 0 2.4rem;
    @include media-breakpoint-down(md) {
      padding: 0;
    }
    .shapes {
      //background: url(./images/overlay-breakout.svg) -0.92rem -0.56rem no-repeat;
      background-size: 85.5%;
      padding: 210px 0 195px;
      padding: 8.4rem 0 7.8rem;
      //padding-bottom: 195px;
      //text-align: right;
      //padding-bottom: 7.8rem;
      // @media (max-width: 1800px) {
      //   background-size: 85.7%;
      //   padding-top: 8.7rem;
      // }
      // @media (max-width: 1600px) {
      //   background-size: 86%;
      //   padding-top: 8.5rem;
      // }
      // @media (max-width: 1400px) {
      //   background-size: 87%;
      // }
      // @include media-breakpoint-down(lg) {
      //   background-size: 89%;
      //   padding-top: 8.8rem;
      // }
      @include media-breakpoint-down(sm) {
        background: url(./images/overlay-breakout-mobile.svg) 6.8vw 5.545vw
          no-repeat; //36px 22px
        background-size: 69.794vw;
        padding: 20.925vw 9.299999999999999vw 3.616666666666666vw
          30.74166666666666vw; //81 36 14 119px 3.24rem 1.44rem 0.56rem 4.76rem
      }
      @media (max-width: 580px) {
        background-position-x: 7.1vw;
      }
      @media (max-width: 430px) {
        background-position-x: 8.7vw;
      }
      @media (max-width: 390px) {
        background-position-x: 8.7vw;
      }
      @media (max-width: 350px) {
        background-position-x: 9vw;
      }

      //text-align: right;
      // padding-left: 404px;
      // padding-left: 16.16rem
      .img-wrap {
        position: relative;
        &:before {
          content: " ";
          display: block;
          position: absolute;
          background: url(./images/overlay-breakout.svg) no-repeat;
          background-size: 41.84rem; // 1046px
          width: 41.84rem; // 1046px
          height: 41.64rem; // 1041px
          top: -9rem; //-225px
          left: 7.92rem; //198px
          @media (max-width: 2000px) {
            left: 0.2rem;
          }
          @media (max-width: 1700px) {
            left: 0.4rem;
          }
          @media (max-width: 1600px) {
            left: 0.5rem;
          }
          @media (max-width: 1500px) {
            left: 0.6rem;
          }
          @media (max-width: 1400px) {
            left: 0.7rem;
          }
          @media (max-width: 1199px) {
            left: -2.7rem;
          }
          @media (max-width: 1100px) {
            left: -2.55rem;
          }
          @media (max-width: 1000px) {
            left: -2.44rem;
          }
          @media (max-width: 991px) {
            left: -6.74rem;
          }
          @media (max-width: 900px) {
            left: -6.58rem;
          }
          @media (max-width: 800px) {
            left: -6.4rem;
          }
          @include media-breakpoint-down(sm) {
            //background: url(./images/overlay-breakout-mobile.svg) 9.3vw 5.645vw no-repeat; //36px 22px
            display: none;
          }
        }
      }
      img {
        width: 23.8rem; // 595px
        height: auto;
        margin-left: 25.55rem;
        @media (max-width: 2000px) {
          margin-left: 0;
          float: right;
        }
        @media (min-width: 2200px) {
          padding-left: 0.12rem;
        }
        @include media-breakpoint-down(sm) {
          width: 60.70833333333333vw; // 9.4rem 235px
        }
      }
    }
    .break-out-panel-text {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      margin-top: 8rem;
      margin-left: 210px;
      margin-left: 8.4rem;
      padding-bottom: 80px;
      @include media-breakpoint-down(lg) {
        margin-left: 6.6rem;
      }
      @include media-breakpoint-down(md) {
        margin-left: 4rem;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 0.56rem; // 14px
        margin-left: 20px;
        margin-right: 20px;
      }
      h2 {
        color: $cerulian;
        font-family: "Centra-Bold";
        font-size: 80px;
        font-size: 3.2rem;
        line-height: 88px;
        line-height: 3.2rem;
        margin-bottom: 90px;
        margin-bottom: 3.6rem;
        //max-width: 14.4rem; //360px
        @include media-breakpoint-down(sm) {
          font-size: 1.6rem; //40px
          line-height: 2.12rem; // 53px
          margin-bottom: 0.76rem; // 19px
        }
      }
      p {
        color: $white;
        font-family: "Centra-Light";
        font-size: 27px;
        font-size: 1.08rem;
        line-height: 35px;
        line-height: 1.4rem;
        letter-spacing: 0.9px;
        letter-spacing: 0.036rem;
        max-width: 23em; //575px
        @include media-breakpoint-down(sm) {
          font-size: 0.56rem; // 14px
          line-height: 0.72rem; //18px
          margin-bottom: 0.76rem; // 19px
        }
        a {
          color: $cerulian;
          font-family: "Centra-Book";
          font-size: 14px;
          //font-size: 0.56rem; maintain legibility at all screen sizes
          letter-spacing: 0.7px;
          //letter-spacing: 0.028rem;
          line-height: 18px;
          //line-height: 0.72rem;
          text-transform: uppercase;
          display: block;
          background: url("./images/chevron.svg") -6px 3px no-repeat;
          //background: url("./images/chevron.svg") -0.24rem 0.12rem no-repeat;
          background-size: 12px; // 0.48rem
          padding-left: 20px;
          padding-left: 0.8rem;
          margin-top: 56px;
          margin-top: 2.24rem;
          @include media-breakpoint-down(sm) {
            margin-top: 0;
            margin-bottom: 1.44rem; //36px
          }
          &:hover {
            color: $pink;
          }
        }
      }
      &.normal-panel {
        margin: 6rem;
        margin-bottom: 0;
        @include media-breakpoint-down(lg) {
          margin: 4rem;
          margin-bottom: 0;
        }
        @include media-breakpoint-down(md) {
          margin: 2rem;
          margin-bottom: 0;
        }
        @include media-breakpoint-down(sm) {
          margin: 1rem;
          margin-bottom: 0;
        }
      }
    }
  }
  .normal-image {
    padding: 6rem;
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 6rem;
    @include media-breakpoint-down(lg) {
      padding: 4rem;
      padding-right: 0;
      padding-bottom: 0;
      margin-bottom: 4rem;
    }
    @include media-breakpoint-down(md) {
      padding: 2rem;
      padding-right: 0;
      padding-bottom: 0;
    }
    @include media-breakpoint-down(sm) {
      padding: 1rem;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
}

/********************************
*
*  Project Team Module
*
********************************/

.project-team {
  max-width: 2560px;
  margin: 0 auto;
  background: $ivory;
  position: relative;
  &:before,
  &:after {
    content: " ";
    display: block;
    position: absolute;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  &:before {
    background: url(./images/overlay-semi-circle.svg) no-repeat;
    background-size: cover;
    top: 50rem;
    left: 4rem;
    width: 27.2rem; //680px
    height: 17.24rem; //431px
    transform: rotate(-120deg);
    @media (max-width: 2000px) {
      top: 42rem;
      left: 1rem;
    }
    @media (max-width: 1800px) {
      top: 45rem;
      left: 2rem;
    }
    @media (max-width: 1600px) {
      top: 52rem;
      left: 2rem;
    }
    @media (max-width: 1400px) {
      top: 64rem;
      left: 2rem;
    }
    @include media-breakpoint-down(lg) {
      top: 67rem;
      left: -5rem;
    }
  }
  &:after {
    background: url(./images/overlay-parallelogram.svg) no-repeat;
    background-size: cover;
    top: 8rem;
    right: 0rem;
    width: 18.88rem; //472px
    height: 16.36rem; //409px
    @media (max-width: 2000px) {
      top: 5.5rem;
      width: 16.2rem;
      height: 14.04rem;
    }
  }
  .wrapper {
    margin: 1rem 10% 0 25%;
    background: $ivory;
    @include media-breakpoint-between(lg, lg) {
      margin: 1rem 5% 0 16%;
    }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      margin: 1rem 0rem 0 12.5rem;
    }
    @media (min-width: 916px) and (max-width: 991.98px) {
      margin: 1rem 0 0 10.5rem;
    }
    @media (min-width: 830px) and (max-width: 915.98px) {
      margin: 1rem 0 0 4.5rem;
    }
    @media (min-width: 799px) and (max-width: 829.98px) {
      margin: 1rem 0 0 2.5rem;
    }
    @media (min-width: 785px) and (max-width: 798.98px) {
      margin: 1rem 0 0 1.5rem;
    }
    @media (min-width: 768px) and (max-width: 784.98px) {
      margin: 1rem 0 0 0rem;
      padding-left: 1px;
    }
    // switch to full width
    @include media-breakpoint-down(xs) {
      margin: 1rem 0 0 0rem;
    }
    ul {
      width: 100%;
      min-width: 375px;
      li {
        min-width: 10rem;
        list-style-type: none;
        display: inline-block;
        width: 33.33%;
        vertical-align: top;
        padding: 0.4rem;
        color: $ink;
        // switch to full width
        @include media-breakpoint-down(xs) {
          width: 100%;
          padding: 0;
          .bio-wrapper {
            background-color: $ivory;
            margin-top: -40%;
            z-index: 1;
            position: relative;
            margin-left: 16px;
            margin-right: 16px;
            padding: 0.64rem 0.64rem 0.8rem;
          }
        }
        @media (max-width: 450px) {
          .bio-wrapper {
            margin-top: -50%;
          }
        }
        .imageset {
          width: 100%;
          position: relative;
          display: block;
          .rollover {
            // animation-name: rollover-animation;
            // animation-duration: 4s;
            // animation-iteration-count: infinite;
            position: absolute;
            opacity: 0;
            left: 0;
            transition: opacity 0.5s;
            &:hover {
              opacity: 1;
              transition: opacity 0.5s;
            }
          }
        }
        h4 {
          margin: 1rem 0 0 0;
          font-family: "Centra-Bold", sans-serif;
          font-size: 1.08rem; //27px
          line-height: 1.4rem; //35px
          letter-spacing: 0.0216rem; //0.54px
          @media (min-width: 576px) and (max-width: 2360px) {
            font-size: 1.08rem; //27px
            line-height: 1.4rem; //35px
            letter-spacing: 0.5px;
          }
          // switch to full width
          @include media-breakpoint-down(xs) {
            margin-top: 0;
          }
        }
        h5 {
          font-family: "Centra-Light", sans-serif;
          font-size: 0.9rem; //27px
          line-height: 1rem; //35px
          letter-spacing: 0.0216rem; //0.54px
          min-height: 3rem;
          margin-bottom: 1.12rem;
          @media (min-width: 576px) and (max-width: 2360px) {
            font-size: 0.9rem; //27px
            line-height: 1rem; //35px
            letter-spacing: 0.5px;
            min-height: 69px;
            margin-bottom: 26px;
          }
          @include media-breakpoint-down(sm) {
            min-height: auto;
            margin-bottom: 0.56rem;
          }
        }
        p {
          font-size: 0.56rem;
          line-height: 0.72rem;
          letter-spacing: 0.0139rem;
          color: $ink;
          margin: 0 0 0.148rem 0;
          @media (min-width: 576px) and (max-width: 2360px) {
            font-size: 13px;
            line-height: 17px;
            letter-spacing: 0.32px;
            margin-bottom: 3.5px;
          }
          .prefix {
            text-transform: uppercase;
            color: $inklight;
          }
        }
        p.qualifications {
          padding-left: 1.08rem; //27px
          background: url(./images/small-parallelogram.svg) 0rem 0.16rem
            no-repeat; // 4px
          background-size: 0.42rem; //10.5px
          @media (min-width: 576px) and (max-width: 2360px) {
            padding-left: 25px;
            background-size: 9.6px;
            background-position-y: 3.7px;
          }
        }
        p.location {
          padding-left: 1.08rem; //27px
          padding-top: 0.1rem;
          background: url(./images/half-circle-ink.svg) 0.08rem 0.18rem
            no-repeat; // 2px 4px
          background-size: 0.28rem; //7px
          @media (min-width: 576px) and (max-width: 2360px) {
            padding-left: 25px;
            background-size: 6.5px;
            background-position-x: 1.84px;
            background-position-y: 3px;
          }
        }
        .subtext {
          margin: 0rem 0 0 0;
          min-height: 2.92rem; //73px
          @media (min-width: 576px) and (max-width: 2360px) {
            min-height: 67px;
          }
          @include media-breakpoint-down(sm) {
            min-height: auto;
            margin-bottom: 0.68rem;
          }
        }
        .bio {
          .bio-content {
            height: 3.6rem; // 90px was 3.9rem
            max-height: 3.6rem;
            @media (min-width: 576px) and (max-width: 2360px) {
              max-height: 84px;
              height: 84px;
            }
            // switch to full width
            @include media-breakpoint-down(xs) {
              height: 4.27rem;
              max-height: 4.27rem;
            }
            overflow: hidden;
            //transition: height 0.5s, overflow 0.5s 0;
            transition: all 1.5s;
            &.open {
              height: auto;
              max-height: 1500px;
              // overflow: visible;
              //transition: height 0.5s, overflow 0.5s 0;
              transition: all 1.5s;
            }
            p {
              margin-bottom: 0.72rem; //18px
              @media (min-width: 576px) and (max-width: 2360px) {
                margin-bottom: 16.6px;
              }
            }
          }
          .read-more {
            margin: 1rem 0 1rem 0;
            text-transform: uppercase;
            font-size: 0.56rem;
            cursor: pointer;
            background: url(./images/chevron-down.svg) left 0.05rem no-repeat;
            padding-left: 1rem;
            @media (min-width: 576px) and (max-width: 2360px) {
              font-size: 13px;
              line-height: 19px;
              letter-spacing: 0.45px;
              padding-left: 23px;
              background-position-y: 1.2px;
            }
            // switch to full width
            @include media-breakpoint-down(xs) {
              margin: 0.64rem 0 0.88rem 0;
            }
            &.open {
              background: url(./images/chevron-up.svg) left 0.2rem no-repeat;
              @media (min-width: 576px) and (max-width: 2360px) {
                background-position-y: 4.6px;
              }
            }
          }
        }
      }
    }
    h3.contact-link {
      padding: 1.4rem 0 2.4rem 0;
      margin-left: 50%;

      @media (max-width: 2000px) {
        margin-left: 45%;
      }
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        line-height: 0.76rem;
        padding-top: 0rem;
      }
      @include media-breakpoint-down(xs) {
        margin-left: 15px;
      }
      margin-bottom: 0;
      a {
        color: $cerulian;
        text-transform: uppercase;
        font-family: "Centra-Light";
        font-size: 1.6rem; //40px
        line-height: 2.08rem; // 52px
        letter-spacing: 0;
        padding-left: 3rem;
        background: url(./images/chevron.svg) 0.1rem 0.24rem no-repeat;
        background-size: 1.6rem;
        @include media-breakpoint-down(sm) {
          font-size: 0.76rem; //19px
          line-height: 1rem;
          padding-left: 1.12rem; //28px
          background-size: 1.12rem;
          background-position-x: -0.56rem;
          background-position-y: -0.02rem;
          padding-top: 2px;
          padding-bottom: 2px;
        }
        @media (max-width: 389px) {
          font-size: 0.6rem;
          background-size: 1rem;
        }
      }
    }
  }
}

// @keyframes rollover-animation {
//   0% {
//     opacity: 0;
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }

/********************************
*
*  Join Us Position Module
*
********************************/
.position-list {
  background: $ink;
  color: $white;
  padding-left: 4.1rem;
  padding-right: 10.6rem; //265px
  @include media-breakpoint-down(lg) {
    padding-left: 2.5rem;
  }
  @include media-breakpoint-down(sm) {
    padding-right: 4.1rem;
  }
  @include media-breakpoint-down(xs) {
    padding-left: 25px;
    padding-right: 25px;
  }
  position: relative;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    background: url(./images/overlay-parallelogram.svg) no-repeat;
    background-size: cover;
    top: -8.2rem;
    left: 0rem;
    width: 19.68rem; //492px
    height: 17.04rem; //426px
    @include media-breakpoint-down(lg) {
      top: -5.2rem;
      width: 12rem;
      height: 10.39rem;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .wrapper {
    @include media-breakpoint-up(xl) {
      > div:nth-child(3n) {
        &:after {
          content: " ";
          display: block;
          width: 4000px;
          height: 1px;
          border-bottom: 1px solid #707070;
          position: absolute;
          left: -2000px;
          bottom: 0rem;
        }
      }
    }

    @include media-breakpoint-between(lg, xl) {
      > div:nth-child(2n) {
        &:after {
          content: " ";
          display: block;
          width: 4000px;
          height: 1px;
          border-bottom: 1px solid #707070;
          position: absolute;
          left: -2000px;
          bottom: 0rem;
        }
      }
    }
  }
  .team {
    padding: 3.6rem 0.4rem 3.52rem 0.4rem;
    @include media-breakpoint-down(sm) {
      padding: 1.4rem 0 0.6rem;
    }
    @include media-breakpoint-down(lg) {
      &:after {
        content: " ";
        display: block;
        width: 4000px;
        height: 1px;
        border-bottom: 1px solid #707070;
        position: absolute;
        left: -2000px;
        bottom: 0rem;
      }
    }
    h4 {
      font-family: "Centra-Bold";
      font-size: 1.08rem; //27px
      line-height: 1.4rem; //35px
      letter-spacing: 0.0216rem; //0.54px
      min-height: 2.8rem;
      margin-bottom: 0;
      @media (min-width: 1500px) and (max-width: 2360px) {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.5px;
        min-height: 64px;
      }
      @media (min-width: 1300px) and (max-width: 1499.98px) {
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.5px;
        min-height: 64px;
      }
      @media (min-width: 576px) and (max-width: 1299.98px) {
        font-size: 17px;
        line-height: 21px;
        letter-spacing: 0.5px;
        min-height: 64px;
      }
    }
    .contact-block {
      margin: 0.6rem 0 1.2rem; //30px
      @include media-breakpoint-down(sm) {
        margin: 0.28rem 0 0.56rem;
      }
      h5 {
        font-size: 0.56rem;
        line-height: 0.72rem;
        letter-spacing: 0.0139rem;
        margin-bottom: 0;
        @media (min-width: 576px) and (max-width: 2360px) {
          font-size: 13px;
          line-height: 17px;
          letter-spacing: 0.32px;
        }
        &.contact {
          a {
            color: $white;
          }
          padding-left: 1.08rem; //27px
          background: url(./images/small-parallelogram-white.svg) 0rem 0.16rem
            no-repeat; // 4px
          background-size: 0.42rem; //10.5px
          @media (min-width: 576px) and (max-width: 2360px) {
            padding-left: 25px;
            background-size: 9.6px;
            background-position-y: 3.7px;
          }
        }
        &.location {
          padding-left: 1.08rem; //27px
          padding-top: 0.1rem;
          background: url(./images/half-circle-white.svg) 0.08rem 0.15rem
            no-repeat; // 2px 4px
          background-size: 0.28rem; //7px
          @media (min-width: 576px) and (max-width: 2360px) {
            padding-left: 25px;
            background-size: 6.5px;
            background-position-x: 1.84px;
            background-position-y: 3px;
          }
        }
        .prefix {
          text-transform: uppercase;
          color: $inklight;
        }
      }
    }
    .job-description-area {
      .description-content {
        p {
          font-size: 0.56rem;
          line-height: 0.72rem;
          letter-spacing: 0.0139rem;
          margin-bottom: 0.72rem;
          @media (min-width: 576px) and (max-width: 2360px) {
            font-size: 13px;
            line-height: 17px;
            letter-spacing: 0.32px;
            margin-bottom: 17px;
          }
        }
      }
      .read-more {
        margin: 0.8rem 0 0 0; //20px
        text-transform: uppercase;
        font-size: 0.56rem;
        color: $white;
        cursor: pointer;
        background: url(./images/chevron.svg) -0.3rem 0.1rem no-repeat;
        padding-left: 1rem;
        @media (min-width: 576px) and (max-width: 2360px) {
          font-size: 13px;
          line-height: 19px;
          letter-spacing: 0.45px;
          padding-left: 23px;
          background-position-y: 1.2px;
        }
        // switch to full width
        @include media-breakpoint-down(sm) {
          margin: 0.64rem 0 0.88rem 0;
        }
        a {
          color: $ivory;
        }
      }
    }
  }
}

/********************************
*
*  Two Column Panel Module
*
********************************/

.two-column-panel {
  // padding-top: 3.6rem; //90px
  // padding-bottom: 2.4rem; //60px
  // @include media-breakpoint-between(sm, lg) {
  //   padding-top: 1.08rem; //27px
  //   padding-bottom: 1.2rem; //30px;
  // }
  // @include media-breakpoint-down(sm) {
  //   padding-top: 1.08rem; //27px
  //   padding-bottom: 1.2rem; //30px;
  // }
  .panel-left {
    position: relative;
    color: $white;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      bottom: 0;
      right: -5.5rem;
      z-index: 10;
      background: url(./images/overlay-parallelogram.svg) no-repeat;
      background-size: cover;
      width: 19.68rem; //492px
      height: 17.04rem; //426px
      -webkit-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
      @include media-breakpoint-down(lg) {
        bottom: 0rem;
        width: 12rem;
        height: 10.39rem;
        right: -1.96rem;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    img {
      float: left;
      height: 42.32rem;
      width: 9.48rem;
      margin-left: 2.16rem; //54px
      margin-top: 3.6rem; //90px
      margin-bottom: 2.4rem; //60px
      @include media-breakpoint-between(sm, lg) {
        height: 30rem;
        width: 6.75rem;
        margin-left: 20px;
        margin-top: 0;
        margin-bottom: 0.66rem; //16.5px
      }
      @include media-breakpoint-down(sm) {
        height: 8.8rem; // 220px 8.8rem
        width: 1.98rem; // 49.5px 1.98rem
        margin-left: 20px;
        margin-top: 0;
        margin-bottom: 0.66rem; //16.5px
      }
    }
    h2 {
      color: $ink;
      margin-left: 18.8rem; //470px
      margin-right: 8.2rem; //205px
      @media (max-width: 2000px) {
        margin-left: 15.8rem;
        margin-right: 6.2rem;
      }
      @include media-breakpoint-between(md, lg) {
        margin-left: 14rem;
        margin-right: 2rem;
        font-size: 2.6rem; //27px
        line-height: 2.6rem; //35px
      }
      @include media-breakpoint-between(sm, md) {
        margin-left: 12rem;
        margin-right: 2rem;
        font-size: 2.6rem; //27px
        line-height: 2.6rem; //35px
      }
      @include media-breakpoint-down(sm) {
        margin-left: 12.5rem;
        margin-right: 1rem; //25px;
        font-size: 1.08rem; //27px
        line-height: 1.4rem; //35px
      }
      @include media-breakpoint-down(xs) {
        margin-left: 4.64rem; //116px;
        margin-right: 1rem; //25px;
      }
      strong {
        color: $cerulian;
        @include media-breakpoint-down(sm) {
          font-size: 2rem;
          line-height: 2rem;
        }
      }
    }
    h2:first-of-type {
      margin-top: 18.72rem; //468px
      margin-bottom: 0.96rem; //24px
      @include media-breakpoint-between(md, lg) {
        margin-top: 12.5rem;
      }
      @include media-breakpoint-between(sm, md) {
        margin-top: 14rem;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem; //10px
      }
    }
    &.white {
      background: $white;
      color: $ink;
    }
  }
  .panel-right {
    background-color: $ink;
    color: $white;
    padding: 394px 470px 139px 205px;
    padding: 15.76rem 18.8rem 5.56rem 8.2rem !important;
    @media (max-width: 2000px) {
      padding-right: 8.2rem !important;
    }
    @include media-breakpoint-between(sm, lg) {
      padding: 10.5rem 5.5rem 7.5rem 4.5rem !important;
    }
    @include media-breakpoint-down(sm) {
      margin-left: 30px;
      margin-right: 30px;
      padding: 86px 28px 5px;
      padding: 3.44rem 1.12rem 0.2rem !important;
    }
    position: relative;
    &:before,
    &:after {
      content: " ";
      display: block;
      position: absolute;
      z-index: 10;
    }
    &:before {
      background: url(./images/cursor.png) no-repeat;
      background-size: 0.64rem;
      width: 0.64rem; //16px
      height: 0.64rem; //16px
      top: 5.6rem; // 140px
      left: 8.24rem; // 206px
      @include media-breakpoint-between(sm, lg) {
        top: 50px;
        left: 4.5rem;
      }
      @include media-breakpoint-down(sm) {
        top: 35px;
        left: 28px;
      }
    }
    &:after {
      background: url(./images/overlay-square.svg) no-repeat;
      background-size: cover;
      width: 3.26rem; // 81.5px
      height: 3.26rem; // 81.5px
      top: 5.6rem; // 140px
      right: 8.04rem; // 201px
      @include media-breakpoint-between(sm, lg) {
        width: 2.8rem;
        height: 2.8rem;
        top: 50px;
        right: 4rem;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    p {
      font-size: 1.08rem; //27px
      line-height: 1.4rem; //35px
      letter-spacing: 0.0216rem; //0.54px
      @include media-breakpoint-down(sm) {
        font-size: 0.56rem;
        line-height: 0.72rem;
        letter-spacing: 0.0139rem;
      }
    }
    &.white {
      background: $white;
      color: $ink;
    }
  }

}

/********************************
*
*  Locations Module
*
********************************/

.locations-panel {
  margin: 4rem 0;
  @include media-breakpoint-down(sm) {
    margin-top: 1.2rem;
    margin-bottom: 1.12rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .pull-left {
    margin-right: -0.5rem;
  }
  h3 {
    font-family: "Centra-Bold", sans-serif;
    color: $ink;
    font-weight: 300;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0.7rem;
    }
  }
  ul {
    li {
      font-size: 0.56rem;
      line-height: 0.72rem;
      letter-spacing: 0.0139rem;
      color: $ink;
      padding-bottom: 0.2rem;
      @media (min-width: 576px) and (max-width: 2360px) {
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.32px;
        margin-bottom: 3.5px;
      }
      a {
        color: $ink;
      }
      .prefix {
        text-transform: uppercase;
        color: $inklight;
      }
      &.email {
        padding-left: 1.08rem; //27px
        background: url(./images/small-parallelogram.svg) 0rem 0.16rem no-repeat; // 4px
        background-size: 0.42rem; //10.5px
        @media (min-width: 576px) and (max-width: 2360px) {
          padding-left: 25px;
          background-size: 9.6px;
          background-position-y: 3.7px;
        }
      }
      &.phone {
        padding-left: 1.08rem; //27px
        padding-top: 0.1rem;
        background: url(./images/half-circle-ink.svg) 0.08rem 0.18rem no-repeat; // 2px 4px
        background-size: 0.28rem; //7px
        @media (min-width: 576px) and (max-width: 2360px) {
          padding-left: 25px;
          background-size: 6.5px;
          background-position-x: 1.84px;
          background-position-y: 3px;
        }
      }
      &.address {
        padding-left: 1.08rem; //27px
        padding-top: 0.1rem;
        background: url(./images/triangle-ink.svg) 0.08rem 0.18rem no-repeat; // 2px 4px
        background-size: 0.45rem; //7px
        @media (min-width: 576px) and (max-width: 2360px) {
          padding-left: 25px;
          background-size: 9px;
          background-position-x: 1.84px;
          background-position-y: 4px;
        }
      }
    }
  }
}

/********************************
*
*  Contact Form Module
*
********************************/

.contact-form {
  @include media-breakpoint-up(md) {
    .form-area {
      margin-left: -15px;
    }
  }
  .success {
    margin-bottom: 8rem;
    margin-left: -0.5rem;
  }
  h2 {
    font-family: "Centra-Bold", sans-serif;
    color: $ink;
    font-weight: 300;
    font-size: 2rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin-left: -15px;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 1.2rem;
    }
  }
  label {
    width: 100%;
    input {
      outline: $ink;
      width: 100%;
      height: 64px;
      border: 0 none;
      font-size: 0.7rem;
      @media (min-width: 576px) and (max-width: 2000px) {
        font-size: 16px;
      }
      color: $ink;
      padding: 0 1rem;
      display: inline-block;
      margin-bottom: 0.5rem;
      @include media-breakpoint-down(sm) {
        margin-bottom: 0.12rem;
      }
      &::placeholder {
        color: $inklight;
        text-transform: uppercase;
        opacity: 1;
      }
      &.submit {
        text-transform: uppercase;
        font-size: 2rem;
        font-family: "Centra-Light", sans-serif;
        text-align: left;
        margin: 1rem 0 4rem 0;
        background: url(./images/chevron.svg) -1rem center no-repeat;
        background-size: 2rem 2rem;
        padding-left: 2rem;
        color: $cerulian;
        // switch to full width
        @include media-breakpoint-down(sm) {
          padding-left: 1rem;
          background-size: 1rem 1rem;
          background-position-x: -0.5rem;
          font-size: 1.08rem; //27px
          line-height: 1.4rem; //35px
          letter-spacing: 0.0216rem; //0.54px
          margin: 0.64rem 0 0.88rem 0;
        }
      }
    }
    textarea {
      outline: $ink;
      width: 100%;
      border: 0 none;
      font-size: 0.7rem;
      @media (min-width: 576px) and (max-width: 2000px) {
        font-size: 16px;
      }
      color: $ink;
      padding: 1rem;
      &::placeholder {
        color: $inklight;
        text-transform: uppercase;
        opacity: 1;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 1rem;
  }
}

/********************************
*
*  Footer Module
*
********************************/

.footer-panel {
  border-top: 2px solid $ivory;
  background-color: $ink;
  padding-bottom: 1.04rem; //26px
  @include media-breakpoint-down(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer-logo-wrapper {
    margin-top: 30px;
    margin-top: 1.2rem;
    margin-bottom: 62px;
    margin-bottom: 2.48rem;
    padding-top: 49.5px;
    padding-top: 1.98rem;
    background: url("./images/cursor.png") 0px 0px no-repeat;
    background-size: 12px;
    img {
      max-height: 90.5px;
      width: auto;
    }
  }

  ul.footer-contact {
    padding-left: 5px;
    li {
      line-height: 0.72rem;
      a {
        font-family: "Centra-Light";
        color: $white;
        font-size: 14px;
        // font-size: 0.56rem;
        line-height: 18px;
        // line-height: 0.72rem;
        letter-spacing: 0.7px;
        // letter-spacing: 0.028rem;
        @include media-breakpoint-down(sm) {
          letter-spacing: 0;
        }
        @media (max-width: 330px) {
          font-size: 12px;
        }
        img {
          display: none;
        }
      }
      &:nth-of-type(1n + 3) {
        a {
          img.contact_icon {
            display: block;
            height: 22px;
            height: 0.88rem;
            min-height: 22px;
            width: auto;
            float: left;
            margin: 16px 10px 0 0;
            margin: 0.64rem 0.4rem 0 0;
            padding: 0.64rem 0;
            box-sizing: content-box;
            @include media-breakpoint-down(lg) {
              margin: 16px 10px 0 0;
            }
          }
        }
      }
    }
  }
  ul.footer-locations {
    li {
      font-family: "Centra-Book";
      color: $cerulian;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.7px;
      padding-left: 30px;
      @media (max-width: 330px) {
        font-size: 12px;
        padding-left: 22px;
      }
      padding-bottom: 5px;
      position: relative;
      img {
        height: 13px;
        width: auto;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
  }
    .terms-links {
      bottom: 0;
      left: 40px;
      a {
        color: $white;
        font-size: 14px;
      }
  }
}

/********************************
*
*  Bottom Contact Link  Module
*
********************************/

.bottom-contact-link {
  background: $ivory;
  max-width: 2560px;
  margin: 0 auto 0 auto;
  h3.contact-link {
    padding: 1.4rem 0 2.4rem 0;
    margin-left: 49.7%;
    @media (max-width: 2000px) {
      margin-left: 49.7%;
    }
    @include media-breakpoint-down(sm) {
      margin-left: 20px;
      line-height: 0.76rem;
      padding-top: 0rem;
    }
    @include media-breakpoint-down(xs) {
      margin-left: 20px;
    }
    margin-bottom: 0;
    a {
      color: $cerulian;
      text-transform: uppercase;
      font-family: "Centra-Light";
      font-size: 1.6rem; //40px
      line-height: 2.08rem; // 52px
      letter-spacing: 0;
      padding-left: 3rem;
      background: url(./images/chevron.svg) 0.1rem 0.24rem no-repeat;
      background-size: 1.6rem;
      @include media-breakpoint-down(sm) {
        font-size: 0.76rem; //19px
        line-height: 1rem;
        padding-left: 1.12rem; //28px
        background-size: 1.12rem;
        background-position-x: -0.56rem;
        background-position-y: -0.02rem;
        padding-top: 2px;
        padding-bottom: 2px;
      }
      @media (max-width: 389px) {
        font-size: 0.6rem;
        background-size: 1rem;
      }
    }
  }
}

.superscript {
  font-size: 60%;
  position: relative;
  top: -8px;
}

.center-wrap {
  background: $ivory;

.insta-feed {
  margin: 0 auto ;
  max-width: 1440px;
  padding: 20px;
  padding-bottom: 40px;
  img { 
    width: 220px;
    height: 200px;
    object-fit: cover; 
    padding: 8px;

  }
  h3 a {
    color: $cerulian;
    text-transform: uppercase;
    font-size: 20px;
    background: url(./images/chevron.svg) left 6px no-repeat;
    padding-left: 30px;
    margin-left: 8px;
  }
  .wrapper-insta {
    overflow: hidden;
    overflow-x: scroll;
    margin: 20px 36px;
  .row {
    width: 1300px;
  }
  }
}
}

.content h4 {
  color: $cerulian;
  font-family: "Centra-Bold";
  font-size: 1.08rem !important;
  line-height: 1.4rem !important;
  letter-spacing: 0.036rem;
}
.quote {
    color: $cerulian;
    font-family: "Centra-Bold";
    font-size: 1.08rem;
    line-height: 1.4rem;
    letter-spacing: 0.036rem;
    font-style: italic;
}

.byline {
  color: $cerulian;
  font-size: .8rem;
  line-height: 1rem;
  letter-spacing: 0.036rem;
  display: inline-block;
  padding-left: 20px;
  background: url(./images/parallogram-small-cerulian.svg) left 3px no-repeat;
  &.uppercase {
    text-transform: uppercase;
  }
}

.in-page-link {
  position: relative;
  z-index: 30;
  display: inline-block;
  text-transform: uppercase;
  height: 50px;
  h2 {
    font-size: 1rem !important;
    line-height: 1.3rem;
    padding-bottom: 0px;
    margin: 1rem 0 0 10px;
  padding-left: 26px;
  color: $cerulian;
  background: url(./images/triangle-right.svg) left 2px no-repeat;
  @include media-breakpoint-down(sm) {
    font-size: .7rem !important;
    line-height: 1rem !important;
  }
  &:hover {
    color: $pink;
  }
  }

  
}

.video-panel {
  padding-left: 2rem;
  @include media-breakpoint-down(sm) {
    padding-left: 0rem;
  }
}

.contact-us-link {
  background: $ivory;
  padding: 0 !important;
  margin: -2px 0 0 0 !important;
  max-height: 110px !important;
  overflow: hidden;
  &.blue {
    background: $ink !important;
  }

  .wrapper {
    background: none !important;
    padding: 0 0 0 0 !important;
    margin: 0 !important;
    h3 {
      margin-top: 20px;
      position: relative;
      left: -70px;
    }
  }
}